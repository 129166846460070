import React from 'react'
import IconButton from '@mui/material/IconButton';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

function FollowIcons() {
    return (
        <div id="follow" className="contact-us section" style={{paddingTop:'50px'}}>
        <div className="container">
            <div className="row">
            <div>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} className="followBox">
                <div style={{fontSize:20, fontWeight:600, marginRight:10}} className="followText">
                    Go To My Social Platform 
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <a href="https://www.facebook.com/kanatar.living" target="_blank">
                    <IconButton aria-label="facebook">
                        <FacebookOutlinedIcon style={{color:'#111', fontSize:'25px'}}/>
                    </IconButton>
                    </a>
                    <a href="https://www.instagram.com/khanatarliving/" target="_blank">
                    <IconButton aria-label="instagram">
                        <InstagramIcon style={{color:'#111', fontSize:'25px'}}/>
                    </IconButton>
                    </a>
                    <a href="https://www.linkedin.com/in/khanatar-living-5775531bb/" target="_blank">
                    <IconButton aria-label="linkedin">
                        <LinkedInIcon style={{color:'#111', fontSize:'25px'}}/>
                    </IconButton>
                    </a>
                    <a href="https://github.com/yeminhtwe3467" target="_blank">
                    <IconButton aria-label="github">
                        <GitHubIcon style={{color:'#111', fontSize:'25px'}}/>
                    </IconButton>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default FollowIcons
