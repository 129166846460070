import React from 'react'
import IconButton from '@mui/material/IconButton';
import SimpleLineIcon from 'react-simple-line-icons';

function Footer() {
    var date = new Date()
    var year = date.getFullYear()

    return (
        <footer>
        <div className="container">
            <div className="row">
            <div className="col-lg-12" style={{textAlign:'center'}}>
                <a href="#pretop"><IconButton style={{marginTop:'20px', borderRadius:'0px', border:'2px solid #222', padding:'3px 8px'}}>
                    <SimpleLineIcon name="arrow-up" color="#111" style={{fontSize:'25px'}}/>
                </IconButton></a>
                <p>
                Copyright © {year} YMH | Web Development. All Rights Reserved.
                <br />
                Design and Developed by:{" "} YMH
                </p>
            </div>
            </div>
        </div>
        </footer>
    )
}

export default Footer
