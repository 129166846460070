import './App.css';
import Header from './components/Header';
import Nav from './components/Nav';
import MainBanner from './components/MainBanner';
import About from './components/About';
import Service from './components/Service';
import FreeQuote from './components/FreeQuote';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import FollowIcons from './components/FollowIcons';

function App() {
  return (
    <div className="App">
      <Header/>
      <Nav/>
      <MainBanner/>
      <About/>
      <Blog/>
      <Service/>
      <FreeQuote/>
      <Portfolio/>
      <Gallery/>
      <Contact/>
      <FollowIcons/>
      <Footer/>
    </div>
  );
}

export default App;
