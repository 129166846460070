import React from 'react'
import Button from '@mui/material/Button';
import RoomIcon from '@mui/icons-material/Room';

function Blog() {
    return (
        <div id="blog" className="blog">
        <div className="container">
            <div className="row">
            <div
                className="col-lg-4 offset-lg-4  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
            >
                <div className="section-heading">
                <h6>Recent updated</h6>
                <h4>
                    My <em>Story</em>
                </h4>
                <div className="line-dec" />
                </div>
            </div>
            <div
                className="col-lg-6 show-up wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
            >
                <div className="blog-post">
                <div className="thumb">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/blog-post-01.jpg`} alt="story1" />
                </div>
                <div className="down-content">
                    <span className="category">Freelancer</span>
                    <span className="date">March,2020 - Present</span>
                    <p>
                    Web Design and Development. Blog or Company Websites by using web application like Wordpress.
                    </p>
                    <span className="author">
                    <RoomIcon style={{color:'#5e72e4'}}/>
                    Yangon
                    </span>
                    <div className="border-first-button" style={{marginTop:"-10px"}}>
                    <a href="#services">
                    <Button variant="outlined"
                    style={{textTransform:'none', 
                    fontFamily: 'Segoe UI Regular',
                    fontSize:15, paddingLeft:20, paddingRight:20,
                    paddingTop:8, paddingBottom:8,
                    color:'#5e72e4', borderRadius:0, borderWidth:2,
                    borderColor:'#5e72e4'}}
                    >Discover More</Button></a>
                    </div>
                </div>
                </div>
            </div>
            <div
                className="col-lg-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
            >
                <div className="blog-posts">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="post-item">
                        <div className="thumb">
                        
                        <img src={`${process.env.PUBLIC_URL}/assets/images/blog-post-02.jpg`} alt="story2" />
                        
                        </div>
                        <div className="right-content">
                        <div>
                        <span className="category">School</span>
                        <span className="date">Sep, 2019 - Present</span>
                        </div>
                        <p style={{marginTop:'10px'}}>
                            Learning Programming Languages and Starting Website Development in Page Myanmar Training School
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-12">
                    <div className="post-item">
                        <div className="thumb">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/blog-post-03.jpeg`} alt="story3" />
                        </div>
                        <div className="right-content">
                        <div>
                        <span className="category">Data</span>
                        <span className="date">Nov, 2019 - Present</span>
                        </div>
                        <p style={{marginTop:'10px'}}>
                            Team Leader or Data Management in final evaluation and baseline assessment of projects as consultant
                        </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-12">
                    <div className="post-item last-post-item">
                        <div className="thumb">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/blog-post-04.jpg`} alt="story4" />
                        </div>
                        <div className="right-content">
                        <div>
                        <span className="category">NGO</span>
                        <span className="date">June, 2017 - Sep, 2019</span>
                        </div>
                        <p style={{marginTop:'10px'}}>
                            Field Facilitator that had to focus on Monitoring and Evaluation Scope in Chan Myae Myittar Development Association (CMMDA)
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Blog
