import React from 'react'
import SimpleLineIcon from 'react-simple-line-icons';

function Service() {
    return (
        <div id="services" className="services section">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div
                className="section-heading  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
                >
                <h6>My Services</h6>
                <h4>
                    What are <em>Provided</em>
                </h4>
                <div className="line-dec" />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="naccs">
                <div className="grid">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="menu">
                        <div className="first-thumb active">
                            <div className="thumb">
                            <span className="icon">
                                <SimpleLineIcon name="screen-desktop" color="#111" style={{fontSize:'50px'}}/>
                            </span>
                                <label style={{marginTop:'20px'}}><span style={{color:'#5e72e4'}}>Website</span> Development</label>
                            </div>
                        </div>
                        <div>
                            <div className="thumb">
                            <span className="icon">
                                <SimpleLineIcon name="pie-chart" color="#111" style={{fontSize:'50px'}}/>
                            </span>
                            <label style={{marginTop:'20px'}}>Web application and <span style={{color:'#5e72e4'}}>Digital</span> Marketing</label>
                            </div>
                        </div>
                        <div>
                            <div className="thumb">
                            <span className="icon">
                                <SimpleLineIcon name="screen-smartphone" color="#111" style={{fontSize:'50px'}}/>
                            </span>
                            <label style={{marginTop:'20px'}}><span style={{color:'#5e72e4'}}>App</span> Development</label>
                            </div>
                        </div>
                        <div>
                            <div className="thumb">
                            <span className="icon">
                                <SimpleLineIcon name="globe" color="#111" style={{fontSize:'50px'}}/>
                            </span>
                                <label style={{marginTop:'20px'}}>Web <span style={{color:'#5e72e4'}}>Hosting</span></label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <ul className="nacc">
                        <li className="active">
                            <div>
                            <div className="thumb">
                                <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="left-text">
                                    <h4>Website Development</h4>
                                    <p>
                                        Website Development includes company websites, organization websites, blog sites, media sites and also personal websites. 
                                        You can also explore any design of websites in web gallery mentioned below.
                                    </p>
                                    <div className="ticks-list">
                                        <span>
                                        <i className="fa fa-check" /> Developing websites using React JS framework
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Pure HTML, CSS and JS websites
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Web Design with Photoshop, Icon and Logo creation
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Dynamic Websites using PHP and MYSQL Database
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> JQuery, Bootstrap, Responsive Design
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="right-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/services-image.jpg`} alt />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li>
                            <div>
                            <div className="thumb">
                                <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="left-text">
                                    <h4>Web Application and Digital Marketing</h4>
                                    <p>
                                        I'm also developing websites using web applications such as Wordpress and Drupal. For website SEO, use YOAST SEO plugin.
                                    </p>
                                    <div className="ticks-list">
                                        <span>
                                        <i className="fa fa-check" /> WordPress, Drupal Framework and Ecommerce Websites
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Search Engine Management (SEM) and Search Engine Organization (SEO)
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Mobile App & Web API 
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Digital Marketing (Facebook, YouTube, Google Ad)
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="right-image">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/services-image-02.png`}
                                        alt
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li>
                            <div>
                            <div className="thumb">
                                <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="left-text">
                                    <h4>Application Development</h4>
                                    <p>
                                        I'm also building mobile applications for both android and ios platform. I've mentioned app design below with download link.
                                    </p>
                                    <div className="ticks-list">
                                        <span>
                                        <i className="fa fa-check" /> using React Native framework
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> using Expo Cli
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> For Database, using MYSQL and Firebase
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> For API, using rest API
                                        </span><br/>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="right-image">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/services-image-03.png`}
                                        alt
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </li>
                        <li>
                            <div>
                            <div className="thumb">
                                <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="left-text">
                                    <h4>Web hosting service</h4>
                                    <p>
                                        Do you want to keep online your website, I'm servicing website hosting.
                                    </p>
                                    <div className="ticks-list">
                                        <span>
                                        <i className="fa fa-check" /> Web hosting on server 
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Domain name & Cpanel Management  
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Webmail & Subdomain name allocation 
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Annual Server Upgrading Services 
                                        </span><br/>
                                        <span>
                                        <i className="fa fa-check" /> Money back guarantee service on sever breakdown 
                                        </span><br/>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="right-image">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/services-image-04.png`}
                                        alt
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}

export default Service
