import React,{useCallback, useState} from 'react'
import SimpleLineIcon from 'react-simple-line-icons';
import Button from '@mui/material/Button';

function Gallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        {link:'http://memory.yeminhtwe.com/', title:'Memory site like Facebook', src:`${process.env.PUBLIC_URL}/assets/images/gallery1.jpg`},
        {link:'https://clone-8c32b.web.app/', title:'Amazon Clone', src:`${process.env.PUBLIC_URL}/assets/images/gallery2.jpg`},
        {link:'https://facebook-messenger-clone-7ece4.web.app/', title:'Messenger Clone', src:`${process.env.PUBLIC_URL}/assets/images/gallery3.jpg`},
        {link:'https://netflix-clone-3b27f.web.app/', title:'Netflix Clone', src:`${process.env.PUBLIC_URL}/assets/images/gallery4.jpg`},
        {link:'https://clone-55331.web.app/', title:'Youtube Clone', src:`${process.env.PUBLIC_URL}/assets/images/gallery5.jpg`},
        {link:'https://facebook-clone-2428e.web.app/', title:'Facebook Clone', src:`${process.env.PUBLIC_URL}/assets/images/gallery6.jpg`},
    ];
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    return (
        <div id="gallery" className="contact-us section">
        <div className="container">
        
            <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div
                className="section-heading wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
                >
                <h6>Web Gallery</h6>
                <h4>
                    React <em>Website</em>
                </h4>
                <div className="line-dec" />
                </div>
            </div>
            <div
                className="col-lg-12 wow fadeIn"
                data-wow-duration="0.5s"
                data-wow-delay="0.1s"
            >
            <div className="gallery-list">
            {images.map((src, index) => (
                <div className="gallery-img">
                <a href={src.link} target="_blank" ><h6 style={{marginLeft:'2px', marginBottom:'6px', textTransform:'uppercase',
                color:'#5e72e4'}}>{index+1}. {src.title}</h6></a>
                <img
                src={ src.src }
                onClick={ () => openImageViewer(index) }
                key={ index }
                style={{ margin: '2px' }}
                alt={`gallery-${index}`}
                />
                </div>
            ))}
            </div>
            </div>
        </div>
        </div>
        {isViewerOpen && (
            <div
            style={{
                background: 'rgba(0,0,0,0.8)',
                position:'fixed', 
                width:"100%",
                left:0,
                right:0,
                top:0,
                bottom:0,
                margin:'auto',
                height:'100vh',
                zIndex:101,
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column'
            }}
            >
            <div className="mobile-gallery" style={{textAlign:'right', width:'80%'}}>
            <Button className="close-btn-gallery"
            onClick={()=> setIsViewerOpen(false)} style={{marginTop:'-20px', marginBottom:'20px'}}>
            <SimpleLineIcon name="close" style={{color:'#fff', fontSize:30}}/>
            </Button>
            <div>
            <a href={images[currentImage].link} target="_blank"><img src={images[currentImage].src} style={{width:'100%'}}/></a>
            </div>
            </div>
            </div>
        )}
        
        </div>
    )
}

export default Gallery
