import React, {useState} from 'react'
import Button from '@mui/material/Button';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function Nav() {
    
    const [active, setActive] = useState(false)
    const clickAtive = (nav) => {
        setActive(!active)
    }
    return (
        <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
            <div className="container">
            <div className="row">
                <div className="col-12">
                <nav className="main-nav">
                    
                    <a href="index.html" className="logo">
                    <img src={`${process.env.PUBLIC_URL}/logo-top.png`} alt="ymh-logo" style={{width:110}}/>
                    </a>
                    <ul className="nav" style={{display:active?'block':'none'}}>
                    <li className="scroll-to-section"><a href="#top" className="active">Home</a></li>
                    <li className="scroll-to-section"><a href="#about">About</a></li>
                    <li className="scroll-to-section"><a href="#services">Services</a></li>
                    <li className="scroll-to-section"><a href="#portfolio">Projects</a></li>
                    <li className="scroll-to-section"><a href="#gallery">Gallery</a></li>
                    <li className="scroll-to-section"><a href="#contact">Contact</a></li> 
                    <li className="scroll-to-section"><a href="#contact"><Button variant="outlined" style={{textTransform:'none', fontFamily: 'Segoe UI Regular', fontSize:15, color:'#5e72e4', borderColor:'#5e72e4',
                    borderRadius:0, borderWidth:2
                    }}
                    startIcon={<MonetizationOnIcon />}>Hire me
                    </Button></a></li> 
                    </ul>        
                    <a className={!active?'menu-trigger':'menu-trigger active'} onClick={clickAtive}>
                        <span>Menu</span>
                    </a>
                </nav>
                </div>
            </div>
            </div>
        </header>
    )
}

export default Nav
