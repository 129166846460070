import React from 'react'
import Button from '@mui/material/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function FreeQuote() {
    return (
        <div id="free-quote" className="free-quote">
        <div className="container">
            <div className="row">
            <div className="col-lg-4 offset-lg-4">
                <div
                className="section-heading  wow"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
                >
                <h6>Grow your bussiness with app</h6>
                <h4>Applications</h4>
                <div className="line-dec" />
                </div>
            </div>
            <div
                className="col-lg-12 wow"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
            >
                <div className="app">
                    <div className="app-letter">
                        <h4 style={{color:'#fff', marginBottom:'30px'}}>You can download this apps below</h4>
                        <a href="https://u.pcloud.link/publink/show?code=XZp0wxXZKdI4PzTe42yLaLAGJgBl5fqT2k4y" target="_blank"><Button startIcon={<DownloadForOfflineIcon />} variant="outlined"
                        style={{textTransform:'none', 
                        fontFamily: 'Segoe UI Regular', borderRadius:0, borderWidth:2,
                        fontSize:15, paddingLeft:40, paddingRight:40,
                        paddingTop:10, paddingBottom:10,
                        color:'#fff',
                        borderColor:'white'}}
                        >Download Here</Button></a>
                    </div>
                    <div className="app-img">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/app.png`} alt="app-png"/>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="ball-span left">
            <span className="span-150" style={{backgroundColor:'#fff', opacity:0.07, top:"80px"}}></span>
            <span className="span-100" style={{backgroundColor:'#fff', opacity:0.1}}></span>
            <span className="span-75" style={{backgroundColor:'#fff', opacity:0.1}}></span>
            <span className="span-100" style={{backgroundColor:'#fff', opacity:0.1, left:0, right:0, margin:'auto', top:'50%'}}></span>
            <span className="span-150" style={{backgroundColor:'#fff', opacity:0.1, left:0, right:0, margin:'auto'}}></span>
        </div>
        <div className="ball-span right">
            <span className="span-150" style={{backgroundColor:'#fff', opacity:0.07}}></span>
            <span className="span-100" style={{backgroundColor:'#fff', opacity:0.1, top:"100px"}}></span>
            <span className="span-150" style={{backgroundColor:'#fff', opacity:0.1, right:"10%", bottom:"10%", top:'auto'}}></span>
        </div>
        </div>

    )
}

export default FreeQuote
