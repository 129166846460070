import React from 'react'
import IconButton from '@mui/material/IconButton';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

function Header() {
    return (
        <div className="pre-header" id="pretop">
            <div className="container">
            <div className="info">
                <label className="email"><EmailIcon/> ymh@yeminhtwe.com</label>
                <label className="phone"><i className="fa fa-phone"></i>09-253-753-903</label>
            </div>
            
            <div className="social-media">
                <a href="https://www.facebook.com/kanatar.living" target="_blank">
                <IconButton aria-label="facebook">
                    <FacebookOutlinedIcon style={{color:'white'}}/>
                </IconButton>
                </a>
                <a href="https://www.instagram.com/khanatarliving/" target="_blank">
                <IconButton aria-label="instagram">
                    <InstagramIcon style={{color:'white'}}/>
                </IconButton>
                </a>
                <a href="https://www.linkedin.com/in/khanatar-living-5775531bb/" target="_blank">
                <IconButton aria-label="linkedin">
                    <LinkedInIcon style={{color:'white'}}/>
                </IconButton>
                </a>
                <a href="https://github.com/yeminhtwe3467" target="_blank">
                <IconButton aria-label="github">
                    <GitHubIcon style={{color:'white'}}/>
                </IconButton>
                </a>
            </div>
            </div>
        </div>
    )
}

export default Header
