import React from 'react';
import Button from '@mui/material/Button';

function MainBanner() {
    return (
        <>
        <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        >
        <div className="container mainBanner">
            <div className="row">
            <div className="col-lg-12">
                <div className="row">
                <div className="col-lg-6 align-self-center">
                    <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                    >
                    <div className="row leftBanner">
                        <div className="col-lg-12">
                        <h6>Do you need a Website?</h6>
                        <h2>I'm developing these websites</h2>
                        <p>
                            You're available Company Websites, Blog Websites, Ecommerce Websites, Media Websites etc.
                            You can also choose designs or templates as you like in here. My developing system is depending on your needs and desires. 
                            If you want to hire me for whole process of web developing, you can contact below. 
                        </p>
                        </div>
                        <div className="col-lg-12">
                        <div className="scroll-to-section">
                            <a href="#about">
                            <Button style={{textTransform:'none', 
                            fontFamily: 'Segoe UI Regular', backgroundColor:'#5e72e4',
                            fontSize:15, paddingLeft:40, paddingRight:40,
                            paddingTop:8, paddingBottom:8, borderRadius:0,
                            color:'#fff', 
                            borderColor:'#5e72e4'}}
                            className="custom-btn"
                            >Discover more
                            </Button>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 imgMod">
                    <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                    >
                    <img src={`${process.env.PUBLIC_URL}/assets/images/banner-min.png`} alt="banner-photo" />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </>

    )
}

export default MainBanner
