import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

function Portfolio() {
    const options = {
        loop:true,
        items:3,
        responsiveClass: true,
        nav: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            800: {
                items: 2,
            },
            1000: {
                items: 3,
    
            }
        },
        navText : ["<div class='port-arrow'><i class='fa fa-chevron-left'></i></div>","<div class='port-arrow'><i class='fa fa-chevron-right'></i></div>"]
    };
    return (
        <div id="portfolio" className="our-portfolio section">
        <div className="container">
            <div className="row">
            <div className="col-lg-5">
                <div
                className="section-heading wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
                >
                <h6>Our Portofolio</h6>
                <h4>
                    See Our Recent <em>Projects</em>
                </h4>
                <div className="line-dec" />
                </div>
            </div>
            </div>
        </div>
        <div
            className="container-fluid wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
        >
            <div className="row">
            <div className="col-lg-12">
                <OwlCarousel className="owl-carosual" {...options}>
                <div className="item">
                    <a href="http://mylibrary.yeminhtwe.com/">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-1.jpg`} alt='project-1' />
                        </div>
                        <div className="down-content">
                        <h4>WordPress Website</h4>
                        <span>My Library Website</span>
                        <p>Used Coding Method - WordPress Application, Dyab themes</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://cmmda.org">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-2.jpg`} alt='project-2' />
                        </div>
                        <div className="down-content">
                        <h4>Organization Website</h4>
                        <span>Chan Myae Myittar Development Association (CMMDA)</span>
                        <p>Used Coding Method - HTML, CSS, Js and MySQL database, Bootstrap, jQuery libraries</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://fooddelivery.yeminhtwe.com/">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-3.jpg`} alt='project-3' />
                        </div>
                        <div className="down-content">
                        <h4>Dynamic Website</h4>
                        <span>Yangon Food Delivery Website</span>
                        <p>Used Coding Method - HTML, CSS, PHP, MySQL database, Bootstrap, jQuery libraries</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="https://www.counsellingcornermyanmar.com">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-4.jpg`} alt='project-4' />
                        </div>
                        <div className="down-content">
                        <h4>Freelance Web Project</h4>
                        <span>Counselling Corner Myanmar</span>
                        <p>Used Web Application - Wordpress Application</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://phoneshop.yeminhtwe.com/">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-5.jpg`} alt='project-5' />
                        </div>
                        <div className="down-content">
                        <h4>Static Website</h4>
                        <span>Phone Online Shop</span>
                        <p>Used codes - HTML, CSS</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://ymhonlineshop.22web.org/">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-6.jpg`} alt='project-6' />
                        </div>
                        <div className="down-content">
                        <h4>E-Commerence Website</h4>
                        <span>Online Shop Website</span>
                        <p>Used Web Application - Wordpress Application </p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="https://yeminhtwe.com">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-7.jpg`} alt='project-7' />
                        </div>
                        <div className="down-content">
                        <h4>Personal Website</h4>
                        <span>Portfolio Website</span>
                        <p>Used Coding Method - HTML, CSS, PHP, MySQL database, Bootstrap, jQuery libraries</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="https://healthyandhappyfamilies.org">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-8.jpg`} alt='project-8' />
                        </div>
                        <div className="down-content">
                        <h4>Freelance Web Project</h4>
                        <span>Healthy and Happy Families Company</span>
                        <p>Used Web Application - Wordpress Application</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://casino2d.byethost4.com/">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-9.jpg`} alt='project-9' />
                        </div>
                        <div className="down-content">
                        <h4>PHP Website</h4>
                        <span>Good Luck 2D site</span>
                        <p>Used Coding Method - HTML, CSS, PHP, MySQL Database, Bootstrap, jQuery libraries</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://ymh.yeminhtwe.com">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-10.jpg`} alt='project-10' />
                        </div>
                        <div className="down-content">
                        <h4>HTML & CSS Sample Website</h4>
                        <span>My Portfolio Website</span>
                        <p>Used Coding Method - HTML, CSS, PHP, MySQL Database</p>
                        </div>
                    </div>
                    </a>
                </div>
                <div className="item">
                    <a href="http://developmentwindow.22web.org">
                    <div className="portfolio-item">
                        <div className="thumb">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/project-11.jpg`} alt='project-11' />
                        </div>
                        <div className="down-content">
                        <h4>WordPress Website</h4>
                        <span>Online Training Website</span>
                        <p>Used Coding Method - WordPress Application, Dyab themes</p>
                        </div>
                    </div>
                    </a>
                </div>
                </OwlCarousel>
            </div>
            </div>
        </div>
        </div>

    )
}

export default Portfolio
