import React from 'react'

function About() {
    return (
        <div id="about" className="about section">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <div className="row">
                <div className="col-lg-6 aboutImg">
                    <div
                    className="about-left-image  wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                    >
                    <img src={`${process.env.PUBLIC_URL}/assets/images/about.jpg`} alt="about-me" />
                    </div>
                </div>
                <div
                    className="col-lg-6 align-self-center  wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                >
                    <div className="about-right-content">
                    <div className="section-heading">
                        <h6>About Us</h6>
                        <h4>
                        Who am I, <em>YMH</em>
                        </h4>
                        <div className="line-dec" />
                    </div>
                    <p>
                    Studying new codes and designs related for a website developer and finding new opportunities to be a member in many huge IT companies.
                    Leading to be one of developing in both Backend and Frontend websites, web application and mobile apps. Aiming to make many different webistes through own creation and solution.
                    Current target and focus is to be skillful in web developing which can build many huge websites and mobile applications. 
                    </p>
                    <div className="row">
                        <div className="col-lg-4 col-sm-4">
                        <div
                            className="skill-item first-skill-item wow fadeIn"
                            data-wow-duration="1s"
                            data-wow-delay="0s"
                        >
                            <div className="progress" data-percentage={90}>
                            <span className="progress-left">
                                <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                                <div>
                                90%
                                <br />
                                <span>Websites</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                        <div
                            className="skill-item second-skill-item wow fadeIn"
                            data-wow-duration="1s"
                            data-wow-delay="0s"
                        >
                            <div className="progress" data-percentage={30}>
                            <span className="progress-left">
                                <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                                <div>
                                30%
                                <br />
                                <span>Web application</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                        <div
                            className="skill-item third-skill-item wow fadeIn"
                            data-wow-duration="1s"
                            data-wow-delay="0s"
                        >
                            <div className="progress" data-percentage={80}>
                            <span className="progress-left">
                                <span className="progress-bar" />
                            </span>
                            <span className="progress-right">
                                <span className="progress-bar" />
                            </span>
                            <div className="progress-value">
                                <div>
                                80%
                                <br />
                                <span>Mobile app</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    )
}

export default About
