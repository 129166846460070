import React from 'react'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

function Contact() {
    return (
        <div id="contact" className="contact-us section contactBackground">
        
        <div className="container">
        
            <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div
                className="section-heading wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
                >
                <h6>Contact Us</h6>
                <h4>
                    Get In Touch With Us <em>Now</em>
                </h4>
                <div className="line-dec" />
                </div>
            </div>
            
            <div
                className="col-lg-12 wow fadeIn"
                data-wow-duration="0.5s"
                data-wow-delay="0.1s"
            >
            
            <div className="contact-array">
                <div className="contact-info">
                    <div style={{
                        padding:'20px',
                    }}><MarkEmailReadOutlinedIcon className="contact-icon" style={{fontSize:'50px', color:'#111'}}/>
                    </div>
                    <h6> ymh@yeminhtwe.com</h6>
                </div>
                <div className="contact-info">
                <div style={{
                        padding:'20px',
                    }}>
                    <PhoneInTalkOutlinedIcon className="contact-icon" style={{fontSize:'50px', color:'#111'}}/>
                    </div>
                    <h6> 09-253-753-903</h6>
                </div>
                <div className="contact-info">
                <div style={{
                        padding:'20px'
                    }}>
                    <FmdGoodOutlinedIcon style={{fontSize:'50px', color:'#111'}}/>
                    </div>
                    <h6>Hpa-an, Kayin State</h6>
                </div>
            </div>
            <div className="contact-map" style={{padding:'30px', marginTop:'40px'}}>
            <iframe style={{width:'100%',height:'400px', borderRadius:'10px', border:'5px solid #e6e8ea'}}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61085.3324040388!2d97.61601596662925!3d16.884140108455096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c2ced21dccdda7%3A0x304af8c857fd4848!2sHpa-An!5e0!3m2!1sen!2smm!4v1637834572454!5m2!1sen!2smm" allowfullscreen="" loading="lazy"></iframe>
            </div>
            </div>
            </div>
        </div>
        
        </div>
    )
}

export default Contact
